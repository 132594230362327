import { ClipLoader } from "react-spinners";
import styles from "./AddNewPrompt.module.scss";

const override = {
  display: "block",
  margin: "0 auto",
};

const AddNewPrompt = ({ t, newPrompt, promptsActions, isLoadingPrompts }) => {
  return (
    <div className={styles.userPromptWrapper}>
      <input
        type="text"
        value={newPrompt}
        onChange={(ev) => promptsActions.handleNewPromptChange(ev)}
        placeholder={t("savePromptPlaceholder")}
      />
      <button
        onClick={promptsActions.handleAddNewPrompt}
        disabled={isLoadingPrompts || newPrompt.length === 0}
      >
        {isLoadingPrompts ? (
          <ClipLoader
            color={"#ffffff"}
            loading={isLoadingPrompts}
            css={override}
            size={20}
          />
        ) : (
          t("savePromptBtn")
        )}
      </button>
    </div>
  );
};

export default AddNewPrompt;
