import React, { useEffect, useRef, useState } from "react";
import styles from "./LanguageSwitcher.module.scss";
import { useTranslation } from "react-i18next";
import { getCurrentLanguage, langsArr } from "../../services/langs";
import ArrowDown from "../../assets/ArrowDown";

const LanguageSwitcher = ({ t, isLoginPage = false }) => {
  const { i18n } = useTranslation();
  const refTooltip = useRef(null);
  const [open, setOpen] = useState(false);
  const currentLanguage = getCurrentLanguage();
  const [activeLang, setActivelang] = useState(
    langsArr.find((it) => it.alias === currentLanguage)
  );

  useEffect(() => {
    setActivelang(langsArr.find((it) => it.alias === currentLanguage));
  }, [currentLanguage]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOutside = (event) => {
    const element = refTooltip.current;

    if (element && !element.contains(event.target)) {
      setOpen(false);
    }
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("i18nextLng", lng);
  };

  return (
    <div
      className={styles.wrapper}
      ref={refTooltip}
      onClick={() => setOpen(!open)}
    >
      <img src={activeLang?.icon} alt="lang-img" />
      <ArrowDown color={isLoginPage ? "#fff" : "#000"} />
      <div
        className={`${styles.tooltipWrapper} ${
          open ? styles.tooltipWrapperOpen : ""
        }`}
      >
        {langsArr
          .filter((it) => it.alias !== currentLanguage)
          .map((it) => {
            return (
              <div
                key={it.name}
                className={styles.tooltipItem}
                onClick={() => changeLanguage(it.alias)}
              >
                <img src={it.icon} alt={it.alias} style={{ width: "20px" }} />
                <div>{it.name}</div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
