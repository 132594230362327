import React from "react";
import styles from "./PromptsModal.module.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CloseIcon from "../../assets/CloseIcon";
import { useTranslation } from "react-i18next";

const PromptsModal = ({ modalData, onVariableChange, onClose, onCopy }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("components");

  const handleModalClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleEnhancePrompt = () => {
    const enhancedPrompt = modalData.modifiedPrompt;
    localStorage.setItem("enhancedPrompt", enhancedPrompt);
    onClose();
    navigate("/prompt-fixer");
    toast.success(t("toasts.movedToPromptFixer"));
  };

  const handleTextareaOnChange = (e, variable, index) => {
    //resize textarea if needed
    const textarea = e.target;
    textarea.style.height = "auto";
    const maxHeight = 120;
    textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;

    onVariableChange(variable.label, e.target.value, index);
  };

  return (
    <div className={styles.modalWrapper} onClick={handleModalClick}>
      <div className={styles.modal}>
        <h2>{t("prompts.editPrompt")}</h2>
        <p>{modalData.modifiedPrompt}</p>
        {modalData.variables.map((variable, index) => (
          <div key={index} className={styles.variables}>
            <label>{variable.label}:</label>
            <textarea
              value={variable.value || ""}
              onChange={(e) => handleTextareaOnChange(e, variable, index)}
            />
          </div>
        ))}
        <div className={styles.buttons}>
          <button onClick={handleEnhancePrompt}>
            {t("prompts.upgradeInPromptFixer")}
          </button>
          <button onClick={onCopy} className={styles.full}>
            {t("prompts.copyPrompt")}
          </button>
        </div>
        <div className={styles.closeButton} onClick={onClose}>
          <CloseIcon color="#181c32" />
        </div>
      </div>
    </div>
  );
};

export default PromptsModal;
