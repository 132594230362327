import React, { useState, useEffect } from "react";
import styles from "./PromptFixer.module.scss";
import InformationBanner from "../../components/InformationBanner";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import { useAuth } from "../../context/AuthContext";
import MembershipRequired from "../../components/MembershipRequired";
import { useTranslation } from "react-i18next";
import { getPromptActions } from "./utils";
import { useLanguage } from "../../hooks/useLanguage";

const override = {
  display: "block",
  margin: "0 auto",
};

const PromptFixerPage = () => {
  const { t } = useTranslation("promptFixerPage");
  const language = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const [textToSend, setTextToSend] = useState("");
  const [level, setLevel] = useState("basic");
  const [generatedPrompt, setGeneratedPrompt] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const { user } = useAuth();

  const loadingNotificationId = React.useRef(null);

  const promptActions = getPromptActions({
    setTextToSend,
    setLevel,
    generatedPrompt,
    setIsCopied,
    textToSend,
    setIsLoading,
    isLoading,
    setGeneratedPrompt,
    loadingNotificationId,
    level,
    t,
    language,
  });

  useEffect(() => {
    const enhancedPrompt = localStorage.getItem("enhancedPrompt");
    if (enhancedPrompt) {
      setTextToSend(enhancedPrompt);
    }
  }, []);

  return (
    <div className={styles.wrapper}>
      <InformationBanner
        heading={t("grayHeroTitle")}
        text={t("grayHeroDescription")}
      />
      <div className={styles.features}>
        <div className={styles.feature}>✨ {t("attributes.dynamic")}</div>
        <div className={styles.feature}>🤖 {t("attributes.adjustable")}</div>
        <div className={styles.feature}>✅ {t("attributes.editDownload")}</div>
        <div className={styles.feature}>🚀 {t("attributes.generateShare")}</div>
      </div>
      {user.membershipActive ? (
        <>
          <div className={styles.userPrompt}>
            <div className={styles.userPromptWrapper}>
              <input
                type="text"
                value={textToSend}
                onChange={promptActions.handleTextChange}
                onKeyUp={promptActions.handleKeyPress}
                placeholder={t("generatePromptPlaceholder")}
              />
              <button
                onClick={promptActions.handleGeneratePrompt}
                disabled={isLoading || textToSend.length === 0}
              >
                {isLoading ? (
                  <ClipLoader
                    color={"#ffffff"}
                    loading={isLoading}
                    css={override}
                    size={20}
                  />
                ) : (
                  t("generatePromptButtonText")
                )}
              </button>
            </div>
            <div className={styles.level}>
              <label>
                <input
                  type="radio"
                  value="basic"
                  checked={level === "basic"}
                  onChange={promptActions.handleLevelChange}
                />
                {t("levels.basic")}
              </label>
              <label>
                <input
                  type="radio"
                  value="advanced"
                  checked={level === "advanced"}
                  onChange={promptActions.handleLevelChange}
                />
                {t("levels.advanced")}
              </label>
              <label>
                <input
                  type="radio"
                  value="professional"
                  checked={level === "professional"}
                  onChange={promptActions.handleLevelChange}
                />
                {t("levels.professional")}
              </label>
            </div>
          </div>
          <div className={styles.generatedPromptWrapper}>
            <p>{t("generatedPrompt")}</p>
            <textarea
              value={generatedPrompt}
              readOnly
              onClick={promptActions.handleCopyGeneratedPrompt}
              placeholder={t("generatedPromptPlaceholder")}
              className={styles.generatedPrompt}
              disabled={!generatedPrompt}
            />
            <button
              onClick={promptActions.handleCopyGeneratedPrompt}
              className={styles.copyButton}
              disabled={!generatedPrompt}
            >
              {isCopied ? t("copied") : t("copyPromptButton")}
            </button>
          </div>
        </>
      ) : (
        <MembershipRequired />
      )}
    </div>
  );
};

export default PromptFixerPage;
