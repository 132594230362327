import axiosInstance from "./axiosClient";
import {
  removeTokens,
  saveCampusTokenCookie,
  saveRefreshTokenCookie,
  saveTokenCookie,
} from "./cookies/tokenCookies";
import { toast } from "react-toastify";
import axios from "axios";
import appRouter from "./appRouter";
import { t } from "i18next";
import { handleSentryError } from "./utils";

const URL_LOGIN = "auth/login";
const URL_USER = "auth/get-user-data";
const EXCHANGE_CODE = "auth/exchange-code";

export async function getUser() {
  try {
    const response = await axiosInstance.post(URL_USER);
    const { user, success } = response.data;

    if (!success) {
      throw new Error(
        `An error occurred: ${response.status} - ${response.statusText}`
      );
    }

    return user;
  } catch (error) {
    handleSentryError(error, "getUser");

    const is401Error =
      axios.isAxiosError(error) && error.response?.status === 401;
    removeTokens();
    appRouter.navigate("/login");

    if (is401Error === false) {
      toast.error(t("api:auth:fetchUserError"));
    }
  }
}

export const getUserLoginWithCode = async (code, state, hmac) => {
  try {
    const response = await axiosInstance.post(EXCHANGE_CODE, {
      code,
      state,
      hmac,
    });

    const { token, refreshToken, user, success } = response.data;

    if (!success) {
      throw new Error(
        `An error occurred: ${response.status} - ${response.statusText}`
      );
    }

    saveTokenCookie(token);
    saveRefreshTokenCookie(refreshToken);

    return user;
  } catch (error) {
    handleSentryError(error, "getUserLoginWithCode");
    toast.error("Błąd logowania. Spróbuj ponownie.");
    removeTokens();
    appRouter.navigate("/login");
  }
};

export async function getUserLoginData(userData) {
  try {
    const response = await axiosInstance.post(URL_LOGIN, {
      email: userData.email,
      password: userData.password,
    });

    const { token, refreshToken, campusAccessToken, user, success } =
      response?.data;

    if (!success) {
      throw new Error();
    }

    saveTokenCookie(token);
    saveRefreshTokenCookie(refreshToken);
    saveCampusTokenCookie(campusAccessToken);

    return user;
  } catch (error) {
    toast.error(t("api:auth:loginError"));
    return null;
  }
}

export function logOut() {
  removeTokens();
  appRouter.navigate("/login");
}
