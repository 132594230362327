import React, { useState } from "react";
import styles from "./GenerateMidjourneyPrompt.module.scss";
import InformationBanner from "../../components/InformationBanner";
import { useAuth } from "../../context/AuthContext";
import { ClipLoader } from "react-spinners";
import MembershipRequired from "../../components/MembershipRequired";
import { useTranslation } from "react-i18next";

import { getPromptActions } from "./utils";

const override = {
  display: "block",
  margin: "0 auto",
};

const GenerateMidjourneyPromptPage = () => {
  const { t } = useTranslation("generateMidjourneyPromptPage");
  const [isLoading, setIsLoading] = useState(false);
  const [textToSend, setTextToSend] = useState("");
  const [generatedPrompt, setGeneratedPrompt] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  const loadingNotificationId = React.useRef(null);

  const { user } = useAuth();

  const promptActions = getPromptActions({
    textToSend,
    setTextToSend,
    setIsLoading,
    setIsCopied,
    setGeneratedPrompt,
    loadingNotificationId,
    generatedPrompt,
    t,
  });

  return (
    <div className={styles.wrapper}>
      <InformationBanner
        heading={t("grayHeroTitle")}
        text={t("grayHeroDescription")}
      />
      {user.membershipActive ? (
        <>
          <div className={styles.userPrompt}>
            <div className={styles.userPromptWrapper}>
              <input
                type="text"
                value={textToSend}
                onChange={promptActions.handleTextChange}
                placeholder={t("generatePromptPlaceholder")}
              />
              <button
                onClick={promptActions.handleGeneratePrompt}
                disabled={isLoading}
              >
                {isLoading ? (
                  <ClipLoader
                    color={"#ffffff"}
                    loading={isLoading}
                    css={override}
                    size={20}
                  />
                ) : (
                  t("generatePromptButtonText")
                )}
              </button>
            </div>
          </div>
          <div className={styles.generatedPromptWrapper}>
            <p>{t("generatedPrompt")}</p>
            <textarea
              value={generatedPrompt}
              readOnly
              onClick={null}
              placeholder={t("generatedPromptPlaceholder")}
              className={styles.generatedPrompt}
            />
            <button
              onClick={promptActions.handleCopyGeneratedPrompt}
              className={styles.copyButton}
              disabled={!generatedPrompt}
            >
              {isCopied ? t("copied") : t("copyPromptButton")}
            </button>
          </div>
        </>
      ) : (
        <MembershipRequired />
      )}
    </div>
  );
};

export default GenerateMidjourneyPromptPage;
