import React, { useState, useEffect } from "react";
import styles from "./TopBanner.module.scss";
import { useTranslation } from "react-i18next";

const CAMPUS_LINKS = {
  pl: "https://campusai.pl/",
  en: "https://www.campus.ai/uk/",
  es: "https://www.campus.ai/es/"
};

const TopBanner = () => {
  const { t, i18n } = useTranslation(["campusBanner"]);
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  useEffect(() => {
    const isBannerClosed = localStorage.getItem("isBannerClosed");
    if (isBannerClosed === "true") {
      setIsBannerVisible(false);
    }
  }, []);

  const getCampusLink = () => {
    return CAMPUS_LINKS[i18n.language] || CAMPUS_LINKS.pl;
  };

  return isBannerVisible ? (
    <div className={styles.banner}>
      <div className={styles.bannerWrapper}>
        <div className={styles.textWrapper}>
          <p className={styles.text}>{t("text")}</p>
          <a
            href={getCampusLink()}
            target="_blank"
            rel="noreferrer"
            className={styles.button}
          >
            {t("buttonText")}
          </a>
        </div>
      </div>
    </div>
  ) : null;
};

export default TopBanner;
