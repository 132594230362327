import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "./Menu.module.scss";
import { useAuth } from "../../context/AuthContext";
import RenderUserButton from "./components/RenderUserButton";
import LanguageSwitcher from "../LanguageSwitcher";
import { useTranslation } from "react-i18next";

const Menu = () => {
  const { t, i18n } = useTranslation(["menu"]);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [toolsDropdownOpen, setToolsDropdownOpen] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const toolsDropdownRef = useRef(null);

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleMobileMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const handleToolsDropdownToggle = () => {
    setToolsDropdownOpen(!toolsDropdownOpen);
  };

  useEffect(() => {
    if (
      mobileMenuOpen === true &&
      (location.pathname === "/prompt-fixer" ||
        location.pathname === "/generate-midjourney-prompt")
    ) {
      setToolsDropdownOpen(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileMenuOpen]);

  useEffect(() => {
    setMobileMenuOpen(false);
    setToolsDropdownOpen(false);
  }, [location]);

  useEffect(() => {
    const closeDropdownOnOutsideClick = (event) => {
      if (
        toolsDropdownRef.current &&
        !toolsDropdownRef.current.contains(event.target) &&
        !mobileMenuOpen
      ) {
        setToolsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", closeDropdownOnOutsideClick);

    return () => {
      document.removeEventListener("mousedown", closeDropdownOnOutsideClick);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //ukrywaj linki do promptów midjourney w innych językach
  useEffect(() => {
    const currentLang = i18n.language;
    if (currentLang !== 'pl' && 
       (location.pathname === '/lista-promptow-midjourney' || 
        location.pathname === '/generate-midjourney-prompt')) {
      navigate('/');
    }
  }, [location.pathname, i18n.language, navigate]);

  return (
    <div className={styles.menu}>
      <img
        src="logo-header.svg"
        alt="Logo"
        className={styles.menuLogo}
        onClick={handleLogoClick}
      />
      <div
        className={styles.menuLeft}
        style={{ display: `${mobileMenuOpen ? "flex" : "none"}` }}
      >
        <Link
          to="/"
          className={`${styles.menuLink} ${
            location.pathname === "/" ? styles.activeLink : ""
          }`}
        >
          {t("promptsList")}
        </Link>
        {i18n.language === 'pl' && (
          <Link
            to="/lista-promptow-midjourney"
            className={`${styles.menuLink} ${
              location.pathname === "/lista-promptow-midjourney"
                ? styles.activeLink
                : ""
            }`}
          >
            {t("midjourneyPromptsList")}
          </Link>
        )}
        <div className={styles.toolsDropdown} ref={toolsDropdownRef}>
          <button
            onClick={handleToolsDropdownToggle}
            className={`${styles.menuLink}`}
          >
            {t("tools")}
          </button>
          {toolsDropdownOpen && (
            <div className={styles.dropdownContent}>
              <Link
                to="/prompt-fixer"
                className={`${styles.menuLink} ${styles.sublink} ${
                  location.pathname === "/prompt-fixer" ? styles.activeLink : ""
                }`}
              >
                {t("toolsList.promptFixer")}
              </Link>
              {i18n.language === 'pl' && (
                <Link
                  to="/generate-midjourney-prompt"
                  className={`${styles.menuLink} ${styles.sublink} ${
                    location.pathname === "/generate-midjourney-prompt"
                      ? styles.activeLink
                      : ""
                  }`}
                >
                  {t("toolsList.generateMidjourneyPrompt")}
                </Link>
              )}
            </div>
          )}
        </div>
        <Link
          to="/ulubione"
          className={`${styles.menuLink} ${
            location.pathname === "/ulubione" ? styles.activeLink : ""
          }`}
        >
          {t("favorites")}
        </Link>
        <Link
          to="/wlasne-prompty"
          className={`${styles.menuLink} ${
            location.pathname === "/wlasne-prompty" ? styles.activeLink : ""
          } sublink`}
        >
          {t("ownPrompts")}
        </Link>
      </div>
      <div className={styles.menuButtons}>
        <div className={styles.mobileMenuIcon} onClick={handleMobileMenuToggle}>
          <div className={mobileMenuOpen ? styles.open : ""}></div>
          <div className={mobileMenuOpen ? styles.open : ""}></div>
          <div className={mobileMenuOpen ? styles.open : ""}></div>
        </div>
        <div className={styles.menuRight}>
          <LanguageSwitcher t={t} />
          <RenderUserButton user={user} t={t} />
        </div>
      </div>
    </div>
  );
};

export default Menu;
