import styles from "./MembershipRequired.module.scss";
import { useTranslation } from "react-i18next";

const MembershipRequired = () => {
  const { t } = useTranslation("components");

  return (
    <div className={styles.membershipErrorContainer}>
      <h2>{t("membership.forMembersOnlyHeader")}</h2>
      <p>{t("membership.forMembersOnlyParagraph")}</p>
      <a href="https://app.campusai.pl/" target="_blank" rel="noreferrer">
        {t("membership.returnTocampus")}
      </a>
    </div>
  );
};

export default MembershipRequired;
