import CategoryAccordion from "../../CategoryAccordion";
import styles from "./PromptsFilters.module.scss";
import { useTranslation } from "react-i18next";

const PromptsFilters = ({
  handleFormSubmit,
  searchingValue,
  handleSearchingValueChange,
  isLoadingPrompts,
  categories,
  handleCategoryChange,
  selectedCategory,
  prompts,
  handleResetFilters,
  showSubcategories = true,
}) => {
  const { t } = useTranslation(["searchFilters"]);

  return (
    <div className={styles.promptsFilters}>
      <form onSubmit={handleFormSubmit} className={styles.filtersForm}>
        <input
          type="text"
          value={searchingValue}
          onChange={handleSearchingValueChange}
          placeholder={t("searchPromptsPlaceholder")}
          className={styles.searchInput}
        />
        <button
          type="submit"
          className={styles.searchButton}
          disabled={isLoadingPrompts || categories.length === 0}
        >
          {t("searchPromptsBtn")}
        </button>
      </form>
      {categories.length > 0 && (
        <div className={styles.categories}>
          <p> {t("availableCategories")}</p>
          <div className={styles.desktopCategories}>
            {categories.map((categoryObj, index) => (
              <div key={index}>
                <div
                  onClick={() =>
                    handleCategoryChange({
                      category: categoryObj.category,
                      type: "main",
                    })
                  }
                  className={`${styles.category} ${
                    selectedCategory.category === categoryObj.category &&
                    selectedCategory.type === "main"
                      ? styles.activeCategory
                      : ""
                  }`}
                >
                  {categoryObj.category} (
                  {
                    prompts.filter(
                      (prompt) => prompt.category === categoryObj.category
                    ).length
                  }
                  )
                </div>

                {showSubcategories &&
                  selectedCategory.category === categoryObj.category &&
                  categoryObj.subCategories.length > 0 && (
                    <div className={styles.subCategories}>
                      {categoryObj.subCategories.map(
                        (subCategory, subIndex) => (
                          <div
                            key={subIndex}
                            onClick={() =>
                              handleCategoryChange({
                                category: categoryObj.category,
                                subCategory,
                                type: "subcategory",
                              })
                            }
                            className={`${styles.subCategory} ${
                              selectedCategory.subCategory === subCategory &&
                              selectedCategory.type === "subcategory"
                                ? styles.activeCategory
                                : ""
                            }`}
                          >
                            {subCategory} (
                            {
                              prompts.filter(
                                (prompt) =>
                                  prompt.category === categoryObj.category &&
                                  prompt.subCategory === subCategory
                              ).length
                            }
                            )
                          </div>
                        )
                      )}
                    </div>
                  )}
              </div>
            ))}
          </div>
          <div className={styles.mobileCategories}>
            <CategoryAccordion
              categories={categories}
              prompts={prompts}
              handleCategoryChange={handleCategoryChange}
              selectedCategory={selectedCategory}
              withSubs={false}
            />
          </div>
        </div>
      )}
      <button
        onClick={handleResetFilters}
        className={styles.resetFiltersButton}
        disabled={isLoadingPrompts || categories.length === 0}
      >
        {t("resetFilters")}
      </button>
    </div>
  );
};

export default PromptsFilters;
