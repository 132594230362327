import styles from "./Footer.module.scss";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("footer");

  return (
    <div className={styles.wrapper}>
      <div className={styles.inner}>
        <div className={styles.column}>
          <img className={styles.logo} src="/logo-footer.svg" alt="logo"></img>
        </div>
        <div className={styles.column}>
          <div className={styles.contact}>
            <div className={styles.column}>
              <h5>{t("contactLabel")}</h5>
              <p>
                <strong>{t("customerServiceOfficeLabel")}</strong>
                <br />
                info@campusai.pl
              </p>
              <p>
                <strong>{t("bookkeepingOfficeLabel")}</strong>
                <br />
                invoice@campusai.pl
              </p>
            </div>
            <div className={styles.column}>
              <h5>{t("companyDetailsLabel")}</h5>
              <p>
                <strong>CampusAI</strong> P.S.A.
                <br />
                invoice@campusai.pl
                <br />
                00-801 Warszawa
                <br />
                NIP: 5273051714
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.privacy}>
        <div className={`${styles.single} ${styles.footerCopyright}`}>
          <a href="/" target="_blank">
            © 2024 CampusAI
          </a>
        </div>
        <div className={styles.column}>
          <div className={styles.privacyRow}>
            <div className={styles.single}>
              <a
                rel="noreferrer"
                href="https://app.campusai.pl/privacy?_gl=1*1ttn90r*_ga*MjU0NTc0MTE1LjE3MTE0NzUzNTc.*_ga_V0YQQQS7S7*MTcxNTY4OTE5NS42MS4xLjE3MTU2OTE5MTguNjAuMC4xODg4MjQwMDg3"
                target="_blank"
              >
                {t("privacyPolicyLabel")}
              </a>
            </div>
            <div className={styles.single}>
              <a
                rel="noreferrer"
                href="https://app.campusai.pl/terms?_gl=1*1flka0z*_ga*MjU0NTc0MTE1LjE3MTE0NzUzNTc.*_ga_V0YQQQS7S7*MTcxNTY4OTE5NS42MS4xLjE3MTU2OTIwMDMuNTQuMC4xODg4MjQwMDg3"
                target="_blank"
              >
                {t("statuteLabel")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
