import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import "@fontsource/poppins";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { RequireAuth } from "./routes/RequireAuth";

import { AuthProvider } from "./context/AuthContext";
import "@csstools/normalize.css";

import { PromptsProvider } from "./context/PromptsContext";
import { MidjourneyPromptsProvider } from "./context/MidjourneyPromptsContext";
import { FavoritesProvider } from "./context/FavoritesContext";

import PromptsPage from "./views/PromptsPage";
import LoginPage from "./views/LoginPage";
import FavoritesPromptsPage from "./views/FavoritesPromptsPage";
import UserPromptsPage from "./views/UserPromptsPage";
import MidjourneyPromptsPage from "./views/MidjourneyPromptsPage";

import Menu from "./components/Menu";
import TopBanner from "./components/TopBanner";
import NotFoundPage from "./views/NotFoundPage";

import { ToastContainer } from "react-toastify";
import PromptFixerPage from "./views/PromptFixerPage";
import GenerateMidjourneyPromptPage from "./views/GenerateMidjourneyPrompt";
import NotRequireAuth from "./routes/NotRequireAuth";
import AppRouter from "./routes/AppRouter";
import Footer from "./components/Footer";
import { langsArr } from "./services/langs";

const AuthenticatedComponents = ({ children }) => (
  <>
    <TopBanner />
    <Menu />
    <div className="children">{children}</div>
    <Footer />
  </>
);

function AppContent() {
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const lng = searchParams.get("lang");

    if (lng && langsArr.some((lang) => lang.alias === lng)) {
      localStorage.setItem("i18nextLng", lng);
    }
  }, [location]);

  return (
    <>
      <AppRouter />
      <AuthProvider>
        <FavoritesProvider>
          <PromptsProvider>
            <MidjourneyPromptsProvider>
              <ToastContainer />
              <Routes>
                <Route
                  path="/login"
                  element={
                    <NotRequireAuth>
                      <LoginPage />
                    </NotRequireAuth>
                  }
                />
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <AuthenticatedComponents>
                        <PromptsPage />
                      </AuthenticatedComponents>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/lista-promptow-midjourney"
                  element={
                    <RequireAuth>
                      <AuthenticatedComponents>
                        <MidjourneyPromptsPage />
                      </AuthenticatedComponents>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/ulubione"
                  element={
                    <RequireAuth>
                      <AuthenticatedComponents>
                        <FavoritesPromptsPage />
                      </AuthenticatedComponents>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/prompt-fixer"
                  element={
                    <RequireAuth>
                      <AuthenticatedComponents>
                        <PromptFixerPage />
                      </AuthenticatedComponents>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/generate-midjourney-prompt"
                  element={
                    <RequireAuth>
                      <AuthenticatedComponents>
                        <GenerateMidjourneyPromptPage />
                      </AuthenticatedComponents>
                    </RequireAuth>
                  }
                />
                <Route
                  path="/wlasne-prompty"
                  element={
                    <RequireAuth>
                      <AuthenticatedComponents>
                        <UserPromptsPage />
                      </AuthenticatedComponents>
                    </RequireAuth>
                  }
                />
                <Route
                  path="*"
                  element={
                    <RequireAuth>
                      <AuthenticatedComponents>
                        <NotFoundPage />
                      </AuthenticatedComponents>
                    </RequireAuth>
                  }
                />
              </Routes>
            </MidjourneyPromptsProvider>
          </PromptsProvider>
        </FavoritesProvider>
      </AuthProvider>
    </>
  );
}

function App() {
  return (
    <>
      <Helmet>
        <script src="https://cdn.lordicon.com/lordicon.js"></script>
      </Helmet>
      <BrowserRouter
        future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
      >
        <AppContent />
      </BrowserRouter>
    </>
  );
}

export default App;
