import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./i18n";
import * as Sentry from "@sentry/react";

const SENTRY_ENV_NAME = process.env.REACT_APP_SENTRY_ENV_NAME;

Sentry.init({
  dsn: "https://c72b81270dae856f1388037d98c4fac5@o4508217820971008.ingest.de.sentry.io/4508261452349521",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  environment: SENTRY_ENV_NAME,
  // Tracing
  tracesSampleRate: SENTRY_ENV_NAME === "local" ? 0.0 : 1.0, // Wyłącz śledzenie tras w localhost

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // Środowisko developerskie
    // "localhost",
    // "localhost:3000",
    /^https:\/\/devpromptbook\.campusai\.pl/,
    /^https:\/\/plugindev\.campusai\.pl/,

    // Środowisko produkcyjne
    /^https:\/\/promptbook\.campusai\.pl/,
    /^https:\/\/plugin\.campusai\.pl/,
  ],
  // Session Replay
  replaysSessionSampleRate: SENTRY_ENV_NAME === "local" ? 0.0 : 0.1, // Wyłącz Replay w localhost
  replaysOnErrorSampleRate: SENTRY_ENV_NAME === "local" ? 0.0 : 1.0, // Wyłącz Replay na błędach w localhost

  // Wykluczenie zdarzeń w localhost
  beforeSend(event) {
    if (SENTRY_ENV_NAME === "local") {
      return null; // Ignoruj zdarzenie w localhost
    }
    return event; // Prześlij zdarzenie w innych środowiskach
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
