import React from "react";
import styles from "./Login.module.scss";
import { generateHmac, generateState } from "../../utils/oAuthSettings";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../../components/LanguageSwitcher";

const OAUTH_SERVICE_URL = process.env.REACT_APP_OAUTH_SERVICE_URL;
const PROMPTBOOK_KEY = process.env.REACT_APP_PROMPTBOOK_KEY;
const OAUTH_APP_ID = process.env.REACT_APP_OAUTH_APP_ID;

function LoginPage() {
  const { t, i18n } = useTranslation("loginPage");

  const currentLang = i18n.language || localStorage.getItem("i18nextLng") || "pl";

  const handleOAuthLogin = () => {
    const state = generateState();
    const hmac = generateHmac(state, PROMPTBOOK_KEY || "");
    const oauthUrl = `${OAUTH_SERVICE_URL}/oauth?app_id=${OAUTH_APP_ID}&state=${state}&hmac=${hmac}&lang=${currentLang}`;
    window.location.href = oauthUrl;
  };

  return (
    <div className={styles.wrapper}>
      <div
        style={{ display: "flex", position: "absolute", right: 24, top: 16 }}
      >
        <LanguageSwitcher t={t} isLoginPage={true} />
      </div>

      <div className={styles.content}>
        <img src="/bg-promptbook.jpg" alt="" className={styles.introImage} />
        <div className={styles.loginForm}>
          <div className={styles.logo}>
            <img src="/logo-header.svg" alt="Logo" />
          </div>
          <button
            type="submit"
            className={styles.submitButton}
            onClick={handleOAuthLogin}
          >
            {t("loginWithDistrictKey")}
          </button>
          <div className={styles.orDivider}>
            <span> {t("or")}</span>
          </div>
          <div className={styles.additionalButtonsWrapper}>
            <a
              href="https://app.campusai.pl/join"
              className={styles.additionalButtons}
            >
              {t("register")}
            </a>
            <a
              href="https://app.campusai.pl/recovery"
              className={styles.additionalButtons}
            >
              {t("remindPassword")}
            </a>
          </div>

          <a href="https://www.campusai.pl" className={styles.returnButton}>
            {t("returnToCampus")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
