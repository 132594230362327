import React, { createContext, useState, useEffect, useContext } from "react";
import { getUserFavoritesPrompts } from "../services/api";
import { useAuth } from "./AuthContext";
import { useLanguage } from "../hooks/useLanguage";
const FavoritesContext = createContext();

export const useFavorites = () => {
  return useContext(FavoritesContext);
};

export const FavoritesProvider = ({ children }) => {
  const [favorites, setFavorites] = useState([]);
  const [isFavoritesLoading, setIsFavoritesLoading] = useState(true);
  const [isFavoritesError, setIsFavoritesError] = useState(false);
  const { user } = useAuth();
  const language = useLanguage();

  useEffect(() => {
    const loadFavorites = async () => {
      if (user && user.email) {
        const result = await getUserFavoritesPrompts(language);

        if (result.isErrorOccured) {
          setIsFavoritesError(true);
        } else {
          setFavorites(result.promptsId);
        }
        setIsFavoritesLoading(false);
      }
    };

    if (user) {
      loadFavorites();
    }
  }, [user, language]);

  const updateFavoritePrompts = async (updatedFavorites) => {
    try {
      setFavorites(updatedFavorites);
    } catch (error) {
      console.error("Error updating favorites:", error);
    }
  };

  return (
    <FavoritesContext.Provider
      value={{
        favorites,
        isFavoritesLoading,
        isFavoritesError,
        updateFavoritePrompts,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};
