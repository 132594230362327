import DefaultSpinner from "../DefaultSpinner";
import styles from "./PromptsLoading.module.scss";
import { useTranslation } from "react-i18next";

const PromptsLoading = () => {
  const { t } = useTranslation("components");

  return (
    <div className={styles.wrapper}>
      <DefaultSpinner />
      <p>{t("promptsLoading.promptsLoading")}</p>
    </div>
  );
};

export default PromptsLoading;
