import { generatePrompt } from "../../services/api";
import { toast } from "react-toastify";
import { getLanguageToClipboard } from "../../services/langs";
export const getPromptActions = ({
  setTextToSend,
  setLevel,
  generatedPrompt,
  setIsCopied,
  textToSend,
  setIsLoading,
  isLoading,
  setGeneratedPrompt,
  loadingNotificationId,
  level,
  t,
  language,
}) => {
  const handleTextChange = (event) => {
    setTextToSend(event.target.value);
  };

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const handleCopyGeneratedPrompt = () => {
    if (!generatedPrompt) {
      toast.warn(t("promptActions.noPromptToCopy"));
      return;
    }

    // const settingsLanguage =
    //   localStorage.getItem("responseLanguage") || "polski";

    const settingsLanguage = getLanguageToClipboard();

    navigator.clipboard.writeText(
      `${generatedPrompt}\n\n${settingsLanguage}`
    );

    setIsCopied(true);
    toast.info(t("promptActions.promptCopySuccess"));

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const handleGeneratePrompt = async () => {
    if (!textToSend) {
      toast.warn(t("promptActions.promtGenereteNoText"));
      return;
    }

    setIsLoading(true);
    setIsCopied(false);
    setGeneratedPrompt("");

    loadingNotificationId.current = toast.info(
      t("promptActions.promptGenereateInProgress"),
      {
        autoClose: false,
      }
    );

    try {
      const response = await generatePrompt(textToSend, level, language);
      setGeneratedPrompt(response.message);
      toast.success(t("promptActions.promptGenerateSuccess"));
    } catch (error) {
      console.error("Error generating prompt:", error);
      toast.error(t("promptActions.promptGenerateError"));
    } finally {
      setIsLoading(false);
      localStorage.removeItem("enhancedPrompt");

      if (loadingNotificationId.current) {
        toast.dismiss(loadingNotificationId.current);
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !isLoading) {
      handleGeneratePrompt();
    }
  };

  return {
    handleTextChange,
    handleLevelChange,
    handleCopyGeneratedPrompt,
    handleGeneratePrompt,
    handleKeyPress,
  };
};
