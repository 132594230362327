import { generateMidjourneyPrompt } from "../../services/api";
import { toast } from "react-toastify";

export const getPromptActions = ({
  textToSend,
  setTextToSend,
  setIsLoading,
  setIsCopied,
  setGeneratedPrompt,
  loadingNotificationId,
  generatedPrompt,
  t,
}) => {
  const handleGeneratePrompt = async () => {
    if (!textToSend) {
      toast.warn(t("promptActions.promtGenereteNoText"));
      return;
    }

    setIsLoading(true);
    setIsCopied(false);
    setGeneratedPrompt("");

    loadingNotificationId.current = toast.info(
      t("promptActions.promptGenereateInProgress"),
      {
        autoClose: false,
      }
    );

    try {
      const response = await generateMidjourneyPrompt(textToSend);
      setGeneratedPrompt(response);
      toast.success(t("promptActions.promptGenerateSuccess"));
    } catch (error) {
      console.error("Error generating prompt:", error);
      toast.error(t("promptActions.promptGenerateError"));
    } finally {
      setIsLoading(false);
      if (loadingNotificationId.current) {
        toast.dismiss(loadingNotificationId.current);
      }
    }
  };

  const handleCopyGeneratedPrompt = () => {
    if (!generatedPrompt) {
      toast.warn(t("promptActions.noPromptToCopy"));
      return;
    }

    navigator.clipboard.writeText(`${generatedPrompt}`);

    setIsCopied(true);
    toast.info(t("promptActions.promptCopySuccess"));

    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  const handleTextChange = (event) => {
    setTextToSend(event.target.value);
  };

  return {
    handleGeneratePrompt,
    handleCopyGeneratedPrompt,
    handleTextChange,
  };
};
