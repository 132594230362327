import i18next from "i18next";
// import { LANGS } from "../consts/langs";

export const langsArr = [
  {
    name: "Polski",
    icon: "/langs/poland.svg",
    alias: "pl",
    clipboardMessage: "Odpowiedź przygotuj w języku polskim"
  },
  {
    name: "English",
    icon: "/langs/uk.svg",
    alias: "en",
    clipboardMessage: "Prepare the answer in English"
  },
  {
    name: "Spanish",
    icon: "/langs/spain.svg",
    alias: "es",
    clipboardMessage: "Prepare la respuesta en español"
  },
];

export const getCurrentLanguage = () => {
  return i18next.language || localStorage.getItem("i18nextLng") || "pl";
};

export const getLanguageToClipboard = () => {
  const currentLang = getCurrentLanguage();
  const langObj = langsArr.find(lang => lang.alias === currentLang);
  return langObj?.clipboardMessage || "Odpowiedź przygotuj w języku polskim";
};
