import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import DangerIcon from "../../../../assets/DangerIcon";
import HeartIcon from "../../../../assets/HeartIcon";
import styles from "./RenderUserButton.module.scss";
import ConfirmationModal from "../../../ConfirmationModal";
import { logOut } from "../../../../services/auth";
import { useTranslation } from "react-i18next";
// import AnswerLangSelector from "../AnswerLangSelector";

const RenderUserButton = ({ user, t }) => {
  const { i18n } = useTranslation();
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  // const [language, setLanguage] = useState(
  //   localStorage.getItem("responseLanguage") || "polish"
  // );

  const userButtonRef = useRef(null);
  const userInfoBoxRef = useRef(null);

  const CAMPUS_LINKS = {
    pl: "https://app.campusai.pl/",
    en: "https://www.campus.ai/en/",
    es: "https://www.campus.ai/es/"
  };

  const COURSE_LINKS = {
    pl: "https://app.campusai.pl/course/59/kurs-generowania-tekstu-metawarstwy",
    en: "https://uk.campus.ai/course/126/text-and-metalayers---theory",
    es: "https://es.campus.ai/"
  };

  const getCourseLink = () => {
    return COURSE_LINKS[i18n.language] || COURSE_LINKS.pl;
  };

  const getCampusLink = () => {
    return CAMPUS_LINKS[i18n.language] || CAMPUS_LINKS.pl;
  };

  const toggleUserInfo = useCallback(() => {
    setShowUserInfo((prevShowUserInfo) => !prevShowUserInfo);
  }, []);

  const toggleLogoutConfirmation = () => {
    setShowLogoutConfirmation(!showLogoutConfirmation);
  };

  const handleLogout = () => {
    toggleLogoutConfirmation();
    toggleUserInfo();
    logOut();
    // navigate("/login");
  };

  // const handleLanguageChange = (event) => {
  //   const selectedLanguage = event.target.value;
  //   setLanguage(selectedLanguage);
  //   localStorage.setItem("responseLanguage", selectedLanguage);
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        userButtonRef.current &&
        !userButtonRef.current.contains(event.target) &&
        userInfoBoxRef.current &&
        !userInfoBoxRef.current.contains(event.target) &&
        showUserInfo
      ) {
        toggleUserInfo();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showUserInfo, toggleUserInfo]);

  if (user) {
    const shortenedUsername =
      user.username.length > 30
        ? `${user.username.slice(0, 30)}...`
        : user.username;
    return (
      <>
        <img
          src={user.avatar}
          alt="User Logo"
          className={styles.accountButton}
          onClick={toggleUserInfo}
          ref={userButtonRef}
        />
        <div
          className={`${styles.userInfoBox} ${
            showUserInfo ? styles.showUserInfo : ""
          }`}
          ref={userInfoBoxRef}
        >
          <div className={styles.userInfoBoxWrapper}>
            <img
              src={user.avatar}
              alt="User Logo"
              className={styles.accountButton}
            />
            <div className={styles.userInfoBoxHeader}>
              <p title={user.username}>{shortenedUsername}</p>
              <p className={styles.userMembership}>
                {user.membershipActive ? (
                  <>
                    <HeartIcon color="#fff" />{" "}
                    {t("profileOptions.membershipActive")}
                  </>
                ) : (
                  <>
                    <DangerIcon /> {t("profileOptions.membershipInactive")}
                  </>
                )}
              </p>
            </div>
          </div>
          {/* <AnswerLangSelector
            t={t}
            answerLanguage={language}
            handleLanguageChange={handleLanguageChange}
          /> */}
          <div className={styles.buttons}>
            <a
              href={getCourseLink()}
              target="_blank"
              rel="noreferrer"
              className={styles.button}
              style={{ borderColor: "#E3E3E7" }}
            >
              <lord-icon
                src="https://cdn.lordicon.com/uecgmesg.json"
                trigger="hover"
                style={{ width: "60px", height: "60px" }}
              ></lord-icon>
              <span>{t("profileOptions.metaCourse")}</span>
            </a>
            <a
              href={getCampusLink()}
              className={styles.button}
              style={{ borderColor: "#E3E3E7" }}
            >
              <lord-icon
                src="https://cdn.lordicon.com/jdsvypqr.json"
                trigger="hover"
                style={{ width: "60px", height: "60px" }}
              ></lord-icon>
              <span>{t("profileOptions.returnToCampus")}</span>
            </a>
            <div className={styles.button} onClick={toggleLogoutConfirmation}>
              <lord-icon
                src="https://cdn.lordicon.com/jrkpfcoz.json"
                trigger="hover"
                style={{ width: "60px", height: "60px" }}
              ></lord-icon>
              <span>{t("profileOptions.logOff")}</span>
            </div>
          </div>
        </div>
        <ConfirmationModal
          modalData={{
            message: t("profileOptions.confirmLogoutQuestion"),
            confirmText: t("profileOptions.confirmLogout"),
            cancelText: t("profileOptions.cancelLogout"),
          }}
          isOpen={showLogoutConfirmation}
          onConfirm={handleLogout}
          onClose={toggleLogoutConfirmation}
        />
      </>
    );
  } else {
    return (
      <Link to="/login" className={styles.accountButton}>
        Zaloguj
      </Link>
    );
  }
};

export default RenderUserButton;
