import styles from "./PrimaryButton.module.scss";

const PrimaryButton = ({ text, handleOnClick, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={handleOnClick}
      className={styles.primaryButtonWrapper}
    >
      {text}
    </button>
  );
};

export default PrimaryButton;
