import React from "react";
import styles from "./ConfirmationModal.module.scss";
import CloseIcon from "../../assets/CloseIcon";
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({ modalData, isOpen, onConfirm, onClose }) => {
  const { t } = useTranslation(["confirmationModal"]);

  if (!isOpen) return null;

  return (
    <div className={styles.modalWrapper} onClick={onClose}>
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <h2>{t("header")}</h2>
        <p>{modalData.message}</p>
        <div className={styles.buttons}>
          <button onClick={onConfirm}>{modalData.confirmText}</button>
        </div>
        <div className={styles.closeButton} onClick={onClose}>
          <CloseIcon color="#181c32" />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
