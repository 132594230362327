import { toast } from "react-toastify";

import {
  setErrorTrue,
  filterPromptsByCategory,
  filterPromptsBySearch,
  loadNumberOfPrompts,
} from "../../helpers";
import { getLanguageToClipboard } from "../../services/langs";

export const getPaginationActions = ({
  setCurrentPage,
  setMidjourneyPromptsToShow,
  midjourneyPromptsPerPage,
  filteredMidjourneyPrompts,
}) => {
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const start = (page - 1) * midjourneyPromptsPerPage;
    const end = start + midjourneyPromptsPerPage;
    setMidjourneyPromptsToShow(filteredMidjourneyPrompts.slice(start, end));
  };

  return {
    handlePageChange,
  };
};

export const getFiltersActions = ({
  setIsLoadingMidjourneyPrompts,
  midjourneyPrompts,
  selectedCategory,
  setFilteredMidjourneyPrompts,
  searchingValue,
  setCurrentPage,
  midjourneyPromptsPerPage,
  setMidjourneyPromptsToShow,
  setErrors,
  setSelectedCategory,
  setSearchingValue,
  t,
}) => {
  const handleSearchByValue = async () => {
    setIsLoadingMidjourneyPrompts(true);

    const filteredMidjourneyPromptsByCategory = filterPromptsByCategory(
      midjourneyPrompts,
      selectedCategory
    );

    const filteredMidjourneyPrompts = filterPromptsBySearch(
      filteredMidjourneyPromptsByCategory,
      searchingValue,
      true
    );

    setFilteredMidjourneyPrompts(filteredMidjourneyPrompts);
    setCurrentPage(1);

    const midjourneyPromptsToShow = loadNumberOfPrompts(
      filteredMidjourneyPrompts,
      midjourneyPromptsPerPage
    );

    const isAnyPromptToShow = midjourneyPromptsToShow.length > 0;
    if (!isAnyPromptToShow) {
      setFilteredMidjourneyPrompts([]);
      setMidjourneyPromptsToShow([]);
      setIsLoadingMidjourneyPrompts(false);
      if (filteredMidjourneyPromptsByCategory.length > 0) {
        toast.info(t("filtersActions.noPromptsMatchByCriteria"));
      } else {
        toast.info(t("filtersActions.noPromptsIsSelectedCategory"));
      }
      setErrorTrue("noMidjourneyPrompts", setErrors);
      return;
    }

    setMidjourneyPromptsToShow(midjourneyPromptsToShow);

    setIsLoadingMidjourneyPrompts(false);
  };

  const handleResetFilters = () => {
    setSelectedCategory({
      category: "Wszystkie",
      subCategory: null,
      type: "main",
    });
    setSearchingValue("");
    toast.success(t("filtersActions.resetFiltersSuccess"));
  };

  const handleSearchingValueChange = (ev) => {
    setSearchingValue(ev.target.value);
  };

  const handleCategoryChange = (newSelection) => {
    setSelectedCategory(newSelection);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    handleSearchByValue();
  };

  return {
    handleSearchByValue,
    handleResetFilters,
    handleSearchingValueChange,
    handleCategoryChange,
    handleFormSubmit,
  };
};

export const getModalActions = ({ setPromptDetails, setIsModalOpen, t }) => {
  const openModal = (prompt) => {
    setPromptDetails(prompt);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setPromptDetails({});
    setIsModalOpen(false);
  };

  const handleCopyButtonClick = (promptToCopy) => {
    // const settingsLanguage =
    //   localStorage.getItem("responseLanguage") || "polski";

    const settingsLanguage = getLanguageToClipboard();

    navigator.clipboard.writeText(
      `${promptToCopy}\n\n${settingsLanguage}`
    );

    toast.success(t("copyActions.promptCopiedToClipboard"));
  };

  return {
    openModal,
    closeModal,
    handleCopyButtonClick,
  };
};

export async function fetchMidjourneyPrompts(
  setIsLoadingMidjourneyPrompts,
  isContextLoading,
  isContextErrorOccurred,
  setErrors,
  user,
  midjourneyPrompts,
  freePrompts,
  setFilteredMidjourneyPrompts,
  setEntireListOfPrompts,
  midjourneyPromptsPerPage,
  setMidjourneyPromptsToShow,
  t
) {
  if (isContextLoading) {
    return setIsLoadingMidjourneyPrompts(true);
  }
  if (isContextErrorOccurred) {
    setIsLoadingMidjourneyPrompts(false);
    setErrorTrue("fetchingMidjourneyPrompts", setErrors);
    toast.error(t("promptsActions.fetchPromptsError"));
    return;
  }

  let promptsToSet;

  if (user.membershipActive) {
    promptsToSet = midjourneyPrompts;
  } else {
    promptsToSet = freePrompts;
  }

  const isAnyPromptAdded = promptsToSet.length > 0;
  if (!isAnyPromptAdded) {
    setIsLoadingMidjourneyPrompts(false);
    toast.info(t("promptsActions.noPromptsAvailable"));
    setErrorTrue("noMidjourneyPrompts", setErrors);
    return;
  }

  setFilteredMidjourneyPrompts(promptsToSet);
  setEntireListOfPrompts(promptsToSet);

  const midjourneyPromptsToShow = promptsToSet.slice(
    0,
    midjourneyPromptsPerPage
  );
  setMidjourneyPromptsToShow(midjourneyPromptsToShow);

  setIsLoadingMidjourneyPrompts(false);
}
export const handleSearchingValueChange = (ev, setSearchingValue) => {
  setSearchingValue(ev.target.value);
};
