import {
  extractTextBetweenSquareBrackets,
  filterPromptsByCategory,
  filterPromptsBySearch,
  loadNumberOfPrompts,
  setErrorTrue,
} from "../../helpers";
import { toast } from "react-toastify";
import { removePromptFromFavorites } from "../../services/api";
import { getLanguageToClipboard } from "../../services/langs";

export const promptsPerPage = 15;

export const getPaginationActions = ({
  setCurrentPage,
  filteredPrompts,
  setPromptsToShow,
}) => {
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const start = (page - 1) * promptsPerPage;
    const end = start + promptsPerPage;
    setPromptsToShow(filteredPrompts.slice(start, end));
  };

  return { handlePageChange };
};

export const getFiltersActions = ({
  setIsLoadingPrompts,
  favoritePrompts,
  selectedCategory,
  searchingValue,
  setFilteredPrompts,
  setCurrentPage,
  setPromptsToShow,
  setErrors,
  setSearchingValue,
  setSelectedCategory,
  t,
}) => {
  const handleSearchByValue = async () => {
    setIsLoadingPrompts(true);

    const filteredPromptsByCategory = filterPromptsByCategory(
      favoritePrompts,
      selectedCategory
    );

    const filteredPrompts = filterPromptsBySearch(
      filteredPromptsByCategory,
      searchingValue
    );

    setFilteredPrompts(filteredPrompts);
    setCurrentPage(1);

    const promptsToShow = loadNumberOfPrompts(filteredPrompts, promptsPerPage);

    const isAnyPromptToShow = promptsToShow.length > 0;
    if (!isAnyPromptToShow) {
      setFilteredPrompts([]);
      setPromptsToShow([]);
      setIsLoadingPrompts(false);
      if (filteredPromptsByCategory.length > 0) {
        toast.info(t("filtersActions.noPromptsMatchByCriteria"));
      } else {
        toast.info(t("filtersActions.noPromptsIsSelectedCategory"));
      }
      setErrorTrue("noPrompts", setErrors);
      return;
    }

    setPromptsToShow(promptsToShow);

    setIsLoadingPrompts(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    handleSearchByValue();
  };

  const handleSearchingValueChange = (ev) => {
    setSearchingValue(ev.target.value);
  };

  const handleCategoryChange = (newSelection) => {
    setSelectedCategory(newSelection);
  };

  const handleResetFilters = () => {
    setSelectedCategory({
      category: "Wszystkie",
      subCategory: null,
      type: "main",
    });
    setSearchingValue("");
    toast.success(t("filtersActions.resetFiltersSuccess"));
  };

  return {
    handleSearchByValue,
    handleFormSubmit,
    handleSearchingValueChange,
    handleCategoryChange,
    handleResetFilters,
  };
};

export const getModalActions = ({
  modalData,
  setModalData,
  promptToRemoveId,
  updateFavoritePrompts,
  favoritePrompts,
  setFavoritePrompts,
  setFilteredPrompts,
  setPromptsToShow,
  setIsRemoveModalOpen,
  setErrors,
  t,
}) => {
  const openModal = (prompt) => {
    const extractedTextArray = extractTextBetweenSquareBrackets(prompt);

    setModalData({
      isOpen: true,
      variables: extractedTextArray,
      basePrompt: prompt,
      modifiedPrompt: prompt,
    });
  };
  const handleVariableInputChange = (variableName, value, index) => {
    const updatedModalData = { ...modalData };

    updatedModalData.variables[index].value = value;

    updatedModalData.modifiedPrompt = updatedModalData.basePrompt;

    updatedModalData.variables.forEach((variable) => {
      const variablePattern = new RegExp(
        variable.label.replace(/[[\]]/g, "\\$&"),
        "g"
      );

      updatedModalData.modifiedPrompt = updatedModalData.modifiedPrompt.replace(
        variablePattern,
        variable.value || variable.label
      );
    });

    setModalData(updatedModalData);
  };

  const closeModal = () => {
    setModalData({
      isOpen: false,
      variables: [],
      modifiedPrompt: "",
    });
  };

  const confirmRemoveFromFavorites = async () => {
    try {
      await removePromptFromFavorites(promptToRemoveId, updateFavoritePrompts);
      const updatedFavorites = favoritePrompts.filter(
        (prompt) => prompt._id !== promptToRemoveId
      );
      setFavoritePrompts(updatedFavorites);
      setFilteredPrompts(updatedFavorites);
      const promptsToShow = loadNumberOfPrompts(
        updatedFavorites,
        promptsPerPage
      );
      setPromptsToShow(promptsToShow);
      setIsRemoveModalOpen(false);
      toast.success(t("modalActions.promptRemovedFavoritesSuccess"));
    } catch (error) {
      console.error("Error removing from favorites:", error);
      setErrorTrue("fetchingFavoritePrompts", setErrors);
      toast.error(t("modalActions.promptRemoveFavoritesError"));
    }
  };

  return {
    handleVariableInputChange,
    closeModal,
    openModal,
    confirmRemoveFromFavorites,
  };
};

export const getCopyActions = ({ modalData, t }) => {
  const copyModifiedPrompt = () => {
    // const settingsLanguage =
    //   localStorage.getItem("responseLanguage") || "polski";
    const settingsLanguage = getLanguageToClipboard();

    navigator.clipboard.writeText(
      `${modalData.modifiedPrompt}\n\n${settingsLanguage}`
    );
    toast.success(t("copyActions.promptCopiedToClipboard"));
  };

  const handleCopyButtonClick = (promptToCopy) => {
    // const settingsLanguage =
    //   localStorage.getItem("responseLanguage") || "polski";

    const settingsLanguage = getLanguageToClipboard();

    navigator.clipboard.writeText(
      `${promptToCopy}\n\n${settingsLanguage}`
    );

    toast.success(t("copyActions.promptCopiedToClipboard"));
  };

  return {
    copyModifiedPrompt,
    handleCopyButtonClick,
  };
};

export const fetchPrompts = async ({
  isContextLoading,
  setIsLoadingPrompts,
  isContextErrorOccurred,
  setErrors,
  prompts,
  isFavoritesLoading,
  isFavoritesError,
  favorites,
  setFavoritePrompts,
  setFilteredPrompts,
  setPromptsToShow,
  t,
}) => {
  if (isContextLoading) {
    return setIsLoadingPrompts(true);
  }
  if (isContextErrorOccurred) {
    setIsLoadingPrompts(false);
    toast.error(t("promptsActions.fetchPromptsError"));
    setErrorTrue("fetchingPrompts", setErrors);
    return;
  }

  const isAnyPromptAdded = prompts.length > 0;
  if (!isAnyPromptAdded) {
    setIsLoadingPrompts(false);
    toast.info(t("promptsActions.noPromptsAvailable"));
    setErrorTrue("noPrompts", setErrors);
    return;
  }

  if (isFavoritesLoading) {
    return setIsLoadingPrompts(true);
  }

  if (isFavoritesError) {
    setIsLoadingPrompts(false);
    toast.error(t("promptsActions.fetchPromptsError"));
    setErrorTrue("fetchingFavoritePrompts", setErrors);
    return;
  }

  const isAnyFavoritePromptAdded = favorites.length > 0;
  if (!isAnyFavoritePromptAdded) {
    setIsLoadingPrompts(false);
    toast.info(t("promptsActions.noPromptsAvailable"));
    setErrorTrue("noFavoritePrompts", setErrors);
    return;
  }

  const favoritePrompts = prompts.filter((prompt) =>
    favorites.includes(prompt._id)
  );

  setFavoritePrompts(favoritePrompts);
  setFilteredPrompts(favoritePrompts);

  const promptsToShow = favoritePrompts.slice(0, promptsPerPage);
  setPromptsToShow(promptsToShow);

  setIsLoadingPrompts(false);
};
