import styles from "./FoundResults.module.scss";
import { useTranslation } from "react-i18next";

const FoundResults = ({ count }) => {
  const { t } = useTranslation("components");

  return (
    <p className={styles.promptsCounter}>
      {t("foundResults.foundResults")} {count} {t("foundResults.results")}
    </p>
  );
};

export default FoundResults;
