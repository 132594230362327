import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { getCurrentLanguage } from "./services/langs";

//pl translations
import translationCampusBannerPL from "./translations/pl/campusBanner.json";
import translationMenuPL from "./translations/pl/menu.json";
import translationConfirmationModalPL from "./translations/pl/confirmationModal.json";
import translationFiltersPL from "./translations/pl/searchFilters.json";
import translationPromptsPagePL from "./translations/pl/promptsPage.json";
import translationMidjourneyPromptsPagePL from "./translations/pl/midjourneyPromptsPage.json";
import translationPromptFixerPagePL from "./translations/pl/promptFixerPage.json";
import translationApiPL from "./translations/pl/api.json";
import translationGenerateMidjourneyPromptsPagePL from "./translations/pl/generateMidjourneyPrompt.json";
import translationFavoritePromptsPagePL from "./translations/pl/favoritesPromptsPage.json";
import translationUserPromptsPagePL from "./translations/pl/userPromptsPage.json";
import translationLoginPagePL from "./translations/pl/loginPage.json";
import translationComponentsPL from "./translations/pl/components.json";
import translationFooterPL from "./translations/pl/footer.json";
import translation404PL from "./translations/pl/404.json";

//english translations
import translationCampusBannerEN from "./translations/en/campusBanner.json";
import translationMenuEN from "./translations/en/menu.json";
import translationConfirmationModalEN from "./translations/en/confirmationModal.json";
import translationFiltersEN from "./translations/en/searchFilters.json";
import translationPromptsPageEN from "./translations/en/promptsPage.json";
import translationMidjourneyPromptsPageEN from "./translations/en/midjourneyPromptsPage.json";
import translationPromptFixerPageEN from "./translations/en/promptFixerPage.json";
import translationApiEN from "./translations/en/api.json";
import translationGenerateMidjourneyPromptsPageEN from "./translations/en/generateMidjourneyPrompt.json";
import translationFavoritePromptsPageEN from "./translations/en/favoritesPromptsPage.json";
import translationUserPromptsPageEN from "./translations/en/userPromptsPage.json";
import translationLoginPageEN from "./translations/en/loginPage.json";
import translationComponentsEN from "./translations/en/components.json";
import translationFooterEN from "./translations/en/footer.json";
import translation404EN from "./translations/en/404.json";
//espanol translations
import translationCampusBannerES from "./translations/es/campusBanner.json";
import translationMenuES from "./translations/es/menu.json";
import translationConfirmationModalES from "./translations/es/confirmationModal.json";
import translationFiltersES from "./translations/es/searchFilters.json";
import translationPromptsPageES from "./translations/es/promptsPage.json";
import translationMidjourneyPromptsPageES from "./translations/es/midjourneyPromptsPage.json";
import translationPromptFixerPageES from "./translations/es/promptFixerPage.json";
import translationApiES from "./translations/es/api.json";
import translationGenerateMidjourneyPromptsPageES from "./translations/es/generateMidjourneyPrompt.json";
import translationFavoritePromptsPageES from "./translations/es/favoritesPromptsPage.json";
import translationUserPromptsPageES from "./translations/es/userPromptsPage.json";
import translationLoginPageES from "./translations/es/loginPage.json";
import translationComponentsES from "./translations/es/components.json";
import translationFooterES from "./translations/es/footer.json";
import translation404ES from "./translations/es/404.json";

const savedLanguage = getCurrentLanguage();

// Get browser language and convert to our supported language codes
const getBrowserLanguage = () => {
  const browserLang = navigator.language || navigator.userLanguage;
  // Convert browser language code to our supported format (e.g., 'en-US' -> 'en')
  const langCode = browserLang.split("-")[0];
  // Check if the language is supported
  return ["pl", "en", "es"].includes(langCode) ? langCode : "pl";
};

i18next.use(initReactI18next).init({
  resources: {
    pl: {
      campusBanner: translationCampusBannerPL,
      confirmationModal: translationConfirmationModalPL,
      menu: translationMenuPL,
      searchFilters: translationFiltersPL,
      promptsPage: translationPromptsPagePL,
      midjourneyPromptsPage: translationMidjourneyPromptsPagePL,
      promptFixerPage: translationPromptFixerPagePL,
      api: translationApiPL,
      generateMidjourneyPromptPage: translationGenerateMidjourneyPromptsPagePL,
      favoritesPromptsPage: translationFavoritePromptsPagePL,
      userPromptsPage: translationUserPromptsPagePL,
      loginPage: translationLoginPagePL,
      components: translationComponentsPL,
      footer: translationFooterPL,
      notFound: translation404PL,
    },
    en: {
      campusBanner: translationCampusBannerEN,
      confirmationModal: translationConfirmationModalEN,
      menu: translationMenuEN,
      searchFilters: translationFiltersEN,
      promptsPage: translationPromptsPageEN,
      midjourneyPromptsPage: translationMidjourneyPromptsPageEN,
      promptFixerPage: translationPromptFixerPageEN,
      api: translationApiEN,
      generateMidjourneyPromptPage: translationGenerateMidjourneyPromptsPageEN,
      favoritesPromptsPage: translationFavoritePromptsPageEN,
      userPromptsPage: translationUserPromptsPageEN,
      loginPage: translationLoginPageEN,
      components: translationComponentsEN,
      footer: translationFooterEN,
      notFound: translation404EN,
    },
    es: {
      campusBanner: translationCampusBannerES,
      confirmationModal: translationConfirmationModalES,
      menu: translationMenuES,
      searchFilters: translationFiltersES,
      promptsPage: translationPromptsPageES,
      midjourneyPromptsPage: translationMidjourneyPromptsPageES,
      promptFixerPage: translationPromptFixerPageES,
      api: translationApiES,
      generateMidjourneyPromptPage: translationGenerateMidjourneyPromptsPageES,
      favoritesPromptsPage: translationFavoritePromptsPageES,
      userPromptsPage: translationUserPromptsPageES,
      loginPage: translationLoginPageES,
      components: translationComponentsES,
      footer: translationFooterES,
      notFound: translation404ES,
    },
  },
  fallbackLng: "pl",
  lng:
    new URLSearchParams(window.location.search).get("lang") ||
    getBrowserLanguage() ||
    savedLanguage ||
    "pl",
  debug: true,
});

export default i18next;
