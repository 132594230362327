import styles from "./Error.module.scss";
import { useTranslation } from "react-i18next";

import PrimaryButton from "../../Buttons/PrimaryButton";

const Error = () => {
  const { t } = useTranslation(["components"]);

  const refreshPageHandler = () => {
    window.location.reload();
  };

  return (
    <div className={styles.errorWrapper}>
      <h2>{t("error.errorTitle")}</h2>
      <h4 style={{ marginTop: "12px" }}>{t("error.errorSubtitle")}</h4>
      <div>
        <PrimaryButton
          text={t("error.refreshButton")}
          handleOnClick={refreshPageHandler}
        />
      </div>
    </div>
  );
};

export default Error;
