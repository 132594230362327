const ArrowDown = ({ color }) => {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 5.71094L0 0.710938H10L5 5.71094Z" fill={color} />
    </svg>
  );
};

export default ArrowDown;
