import React from "react";
import styles from "./NotFound.module.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation("notFound");

  return (
    <div className={styles.notFound}>
      <h2>{t("title")}</h2>
      <p>{t("description")}</p>
      <Link to="/">{t("button")}</Link>
    </div>
  );
};

export default NotFoundPage;
